import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import DatePicker from '../utils/DatePicker';
import UploadImage from '../utils/UploadImage';
import { getDatabase, ref, set, onValue, remove, push } from "firebase/database";
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone';
import RichTextEditor from 'html-verify-react-rte';
import { PhotoshopPicker } from "react-color";
import {showConfirmPopup, showErrorPopup, showInfoPopup, showToast} from "../utils/SwalConfig";
import {appDatabasePrimaryFunctions} from "../../base";
import {isEmptyHtmlEntity} from "../utils/HelpfulFunction";

class SetUpTickets extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            ticketList: [],
            editingTicket: false,
            rewardToEdit: null,
            imageUrl: null,
            modal: false,
            emailImage: null,
            rewardName: "",
            rewardsList: [],
            rewardLink: "",
            rewardLinkButtonText: "",
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true,
            rewardDescription: RichTextEditor.createEmptyValue(),
            editTicketId: "",
            editTicketEmailImage: '',
            editTicketPictureUnderScratcher: '',
            revealImagePreview: {},
            emailImagePreview: {},
            loading: true,
            files: [],
            isRedeemable: false,
            advanced: false,
            expiresAtHours: "",
            expiresAtDateTime: null,
            rewardLinkButtonTextColor: "",
            rewardLinkButtonColor: "",
            vendorName: "",
            templateText: "",
        };
    }

    componentDidMount() {
        const rewardsListRef = ref(appDatabasePrimaryFunctions, 'rewardsList');
        onValue(rewardsListRef, (snapshot) => {
            const data = snapshot.val() || {};
            const rewardsList = Object.keys(data).map((key) => {
                const reward = data[key];
                // Check if the reward already has an 'id' property
                if (!reward.id) {
                    reward.id = key;
                }
                return reward;
            });
            this.setState({ rewardsList, loading: false });
        });
    }


    componentWillUnmount() {
        URL.revokeObjectURL(this.state.revealImagePreview.preview);
        URL.revokeObjectURL(this.state.emailImagePreview.preview);
    }

    onDrop = (files, rejected, myArgument) => {
        if (rejected.length > 0) {
            showInfoPopup('Image cannot be uploaded', 'Make sure the image is less than 2MB and it is an accepted file type')
            return;
        }
        this.setState({ loading: true });

        this.UploadImage.upload_file(files[0]).then(res => {
            this.setState({ loading: false });
            if (res.error) {
                showErrorPopup('Image cannot be uploaded', res.error)
            } else if (res.imageUrl) {
                const nameToUpdate = myArgument + "Preview";
                const fileToUpdate = files[0];
                this.setState({
                    emailImage: res.imageUrl,
                    [nameToUpdate]: {
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    }
                });
            } else {
                showInfoPopup('Image cannot be uploaded', "Something went wrong, please re-upload your image and try again!")
            }
        });
    };

    onChangeDescription = (rewardDescription) => {
        this.setState({ rewardDescription });
    };

    openColorPicker = (colorToChange) => {
        this.setState({
            colorPickerShow: true,
            colorToShow: this.state[colorToChange],
            colorToUpdate: colorToChange
        });
    };

    handleChangeComplete = (color) => {
        this.setState({ colorToShow: color.hex });
    };

    acceptPicker = () => {
        const colorToChange = this.state.colorToUpdate;
        this.setState({
            [colorToChange]: this.state.colorToShow,
            updateHappend: true,
            colorPickerShow: false,
        });
    };

    dismissPicker = () => {
        this.setState({ colorPickerShow: false });
    };

    handleChange = (evt) => {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.loading) return;

        const rewardName = this.state.rewardName.trim();
        if (!rewardName) {
            showInfoPopup('Not all fields filled out!', 'Please enter them and try again')
            return;
        }

        if (rewardName.length > 100) {
            showInfoPopup('Oh uh!', 'Prize name cannot be longer than 100 characters!')
            return;
        }

        let rewardLink = this.state.rewardLink || "";
        if (this.state.isRedeemable) rewardLink = "";

        const rewardDescription = isEmptyHtmlEntity(this.state.rewardDescription.toString('html')) ? "" : this.state.rewardDescription.toString('html');
        const emailImage = this.state.emailImage || this.state.editTicketEmailImage || "";

        const rewardToSave = {
            rewardName,
            emailImage,
            rewardLink,
            rewardLinkButtonText: this.state.rewardLinkButtonText || "",
            rewardLinkButtonTextColor: this.state.rewardLinkButtonTextColor || "",
            rewardLinkButtonColor: this.state.rewardLinkButtonColor || "",
            vendorName: this.state.vendorName || "",
            templateText: this.state.templateText || "",
            expiresAtDateTime: this.state.expiresAtDateTime ? this.state.expiresAtDateTime.getTime() : null,
            expiresAtHours: this.state.expiresAtHours || "",
            description: rewardDescription,
            isRedeemable: this.state.isRedeemable,
            showButton: this.state.showButton || false
        };

        this.setState({ loading: true });
        const rewardId = this.state.rewardToEdit ? this.state.rewardToEdit.id : push(ref(appDatabasePrimaryFunctions, 'rewardsList')).key;
        set(ref(appDatabasePrimaryFunctions, 'rewardsList/' + rewardId), rewardToSave)
            .then(() => {
                showToast(this.state.rewardToEdit ? 'Prize Edited!' : 'Prize Created!')
                this.resetForm();
            })
            .catch((err) => {
                this.setState({ loading: false });
                showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in')
                console.log(err);
            });
    };

    resetForm = () => {
        this.setState({
            rewardDescription: RichTextEditor.createEmptyValue(),
            emailImage: null,
            rewardToEdit: null,
            rewardName: "",
            revealImagePreview: {},
            emailImagePreview: {},
            rewardLink: "",
            rewardLinkButtonText: "",
            rewardLinkButtonTextColor: "",
            rewardLinkButtonColor: "",
            expiresAtHours: "",
            expiresAtDateTime: "",
            templateText: "",
            vendorName: "",
            loading: false,
            modal: false,
            isRedeemable: false,
            showButton: false,
            advanced: false
        });
    };

    editTicket = (event) => {
        event.preventDefault();
        const reward = this.state.rewardsList[event.target.value];
        if (!reward) return;

        let advanced = (reward.expiresAtHours && parseInt(reward.expiresAtHours)) || reward.expiresAtDateTime;
        if (reward.expiresAtDateTime) reward.expiresAtDateTime = new Date(reward.expiresAtDateTime);

        this.setState({
            modal: true,
            rewardName: reward.rewardName,
            rewardLink: reward.rewardLink,
            rewardDescription: RichTextEditor.createValueFromString(reward.description, 'html'),
            rewardLinkButtonText: reward.rewardLinkButtonText,
            rewardLinkButtonColor: reward.rewardLinkButtonColor,
            rewardLinkButtonTextColor: reward.rewardLinkButtonTextColor,
            expiresAtHours: reward.expiresAtHours || "",
            expiresAtDateTime: reward.expiresAtDateTime,
            editTicketEmailImage: reward.emailImage,
            rewardToEdit: reward,
            vendorName: reward.vendorName,
            templateText: reward.templateText,
            isRedeemable: reward.isRedeemable,
            editingTicket: true,
            showButton: reward.showButton,
            advanced
        });
    };

    deleteTicket = async (e) => {
        e.preventDefault();
        const reward = this.state.rewardsList[e.target.value];
        const result = await showConfirmPopup('Delete Prize?', 'Are you sure you want to do this?  You will no longer be able to use this prize in any new games', 'Delete')
        if(!result) return;
        remove(ref(appDatabasePrimaryFunctions, 'rewardsList/' + reward.id))
        .then(() => {
            showToast('Prize Deleted!')
        })
        .catch((err) => {
            showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in')
        });
    };

    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
            rewardName: "",
            rewardLink: "",
            rewardDescription: RichTextEditor.createEmptyValue(),
            rewardLinkButtonText: "",
            rewardLinkButtonColor: "",
            rewardLinkButtonTextColor: "",
            editTicketEmailImage: null,
            expiresAtHours: "",
            expiresAtDateTime: "",
            templateText: "",
            vendorName: "",
            rewardToEdit: null,
            editingTicket: false,
            advanced: false,
            isRedeemable: false,
            showButton: false
        }));
    };

    render() {
        const { rewardsList, emailImagePreview, loading, modal, advanced, rewardLinkButtonText, rewardLinkButtonTextColor, rewardLinkButtonColor } = this.state;
        const toolbarConfig = {
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        const isDunkin = process.env.REACT_APP_GAME_VERSION === "dunkin";
        if (!isMlbApp) {
            toolbarConfig.display.push('LINK_BUTTONS');
        }

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="card">
                        <div className="card-body">
                            <p className="admin-header-text" style={{ marginBottom: 0 }}>Prizes</p>
                            <p className="admin-subheader-text">These are prizes fans will receive when they win</p>
                            <button className="btn btn-primary btn-lg create-prize-button" style={{ fontSize: 20, marginLeft: 20 }} onClick={this.toggle}>Add Prize</button>
                            <div className="admin-grid-container four-columns" style={{ marginTop: 20 }}>
                                {
                                    rewardsList.map((item, i) => (
                                        <div key={i} className="card">
                                            <div className="card-body" align="center">
                                                <p style={{ marginTop: 5 }}>{item.rewardName}</p>
                                                <p>
                                                    <img width="80%" height="auto" src={item.emailImage} alt="" />
                                                </p>
                                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight: 5, marginBottom: 10 }} onClick={this.editTicket.bind(this)} value={i}><span className="fa fa-ellipsis-v" /> Edit</button>
                                                <button className="btn btn-primary btn-lg delete-button" style={{ marginBottom: 10 }} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o" /> Delete</button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modal} toggle={this.toggle} style={{ width: '90%' }} id="myModal">
                    <div className="loading-screen" style={{ display: loading ? 'block' : 'none' }} />
                    <ModalHeader toggle={this.toggle}>Add Prize</ModalHeader>
                    <ModalBody>
                        <div className="container-out">
                            <div className="question-box question-form">
                                <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                                    <div className="form-group">
                                        <label htmlFor="rewardName">Prize Name</label>
                                        <input id="rewardName" name="rewardName" type="text" className="form-control" value={this.state.rewardName} onChange={this.handleChange} placeholder={"My Sweet Prize"} />
                                    </div>
                                    {!isDunkin &&
                                        <div className="form-group">
                                            {!isMlbApp &&
                                                <label htmlFor="rewardLink">Prize Link (Optional)</label>
                                            }
                                            {!isMlbApp &&
                                                <span> OR</span>
                                            }
                                            <label htmlFor="showRewardsOutMessage">
                                                Sqwad Redeem Process
                                            </label>
                                            <input type="checkbox" checked={this.state.isRedeemable} id="isRedeemable" name="isRedeemable" onChange={this.handleChange} />
                                            {!this.state.isRedeemable && !isMlbApp &&
                                                <input id="rewardLink" name="rewardLink" type="url" className="form-control" value={this.state.rewardLink} onChange={this.handleChange} placeholder="http://my_sweet_prize_link.com" />
                                            }
                                        </div>
                                    }
                                    {this.state.rewardLink && !isMlbApp &&
                                        <div className="form-group">
                                            <label htmlFor="showButton">Show Button</label>
                                            <br/>
                                            <input type="checkbox" checked={this.state.isRedeemable || this.state.showButton} id="showButton" name="showButton" onChange={this.handleChange} disabled={this.state.isRedeemable && true}/>
                                        </div>
                                    }
                                    {(((this.state.rewardLink && this.state.showButton) || isMlbApp || this.state.isRedeemable) && !isDunkin) &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="rewardLinkButtonText">Button Text</label>
                                                <input id="rewardLinkButtonText" name="rewardLinkButtonText" type="text" className="form-control" value={rewardLinkButtonText} onChange={this.handleChange} placeholder={this.state.isRedeemable && !isMlbApp ? "Store Redeem" : "GET MY PRIZE!"} />
                                            </div>
                                            {!isMlbApp &&
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-inline">
                                                            <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("rewardLinkButtonTextColor")} />
                                                            <div style={{ backgroundColor: rewardLinkButtonTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                            <label htmlFor="rewardLinkButtonTextColor" style={{ marginRight: 10 }}>Button Text Color</label>
                                                            <input style={{ paddingRight: 0 }} id="rewardLinkButtonTextColor" name="rewardLinkButtonTextColor" type="text" className="form-control" value={rewardLinkButtonTextColor} onChange={this.handleChange} placeholder="#000" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-inline">
                                                            <span style={{ marginRight: 10 }} className="fa fa-eyedropper mobile-hide" onClick={() => this.openColorPicker("rewardLinkButtonColor")} />
                                                            <div style={{ backgroundColor: rewardLinkButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block" }} />
                                                            <label htmlFor="rewardLinkButtonColor" style={{ marginRight: 10 }}>Button Color</label>
                                                            <input id="rewardLinkButtonColor" name="rewardLinkButtonColor" type="text" className="form-control" value={rewardLinkButtonColor} onChange={this.handleChange} placeholder="#000" />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                    { (!isMlbApp && !isDunkin) &&
                                        <div className="form-group">
                                            <label htmlFor="rewardDescription">Prize Description: </label>
                                            <RichTextEditor toolbarConfig={toolbarConfig} id="rewardDescription" name="rewardDescription" type="text" value={this.state.rewardDescription} onChange={this.onChangeDescription} placeholder="My Sweet Prize Description" />
                                        </div>
                                    }
                                    {(isMlbApp && !isDunkin) &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="vendorName">Vendor Name</label>
                                                <input id="vendorName" name="vendorName" type="text" className="form-control" value={this.state.vendorName} onChange={this.handleChange} placeholder="Your sponsors name" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="templateText">Template Text</label>
                                                <p style={{ fontSize: '10px', color: 'grey', fontFamily: 'Open Sans' }}>This text should finish the phrase: You have an update from </p>
                                                <input id="templateText" name="templateText" className="form-control" value={this.state.templateText} onChange={this.handleChange} />
                                            </div>
                                        </>
                                    }
                                    {(isMlbApp && !isDunkin) &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="advanced">Advanced</label>
                                                <br />
                                                <input type="checkbox" checked={advanced} id="advanced" name="advanced" onChange={this.handleChange} />
                                            </div>
                                            {(advanced && isMlbApp && !isDunkin) &&
                                                <>
                                                    <div className="form-group">
                                                        <label htmlFor="expiresAtHours">Hours Until Expire</label>
                                                        <input id="expiresAtHours" name="expiresAtHours" step="1" type="number" className="form-control" value={this.state.expiresAtHours} onChange={this.handleChange} placeholder="720" />
                                                    </div>
                                                    OR/AND
                                                    <div className="form-group row mb-3 form_times">
                                                        <label className="col-md-3 col-form-label" htmlFor="expiresAtDateTime"> Date Until Expire</label>
                                                        <div className="col-md-9">
                                                            <DatePicker showTimeInput dateFormat="Pp" selected={this.state.expiresAtDateTime} onChange={date => this.setState({ expiresAtDateTime: date })} />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                    <div className="form-group" align="center">
                                        <label htmlFor="rewardEmailImage" style={{ width: '100%' }}>
                                            Coupon Image
                                            <span style={{ cursor: "pointer", display: emailImagePreview.preview || this.state.editTicketEmailImage ? "" : "none" }} onClick={() => this.setState({ editTicketEmailImage: null, fileUploaded: false, emailImagePreview: {}, emailImage: null })}>
                                                ❌
                                            </span>
                                        </label>
                                        <img src={this.state.editTicketEmailImage} width="auto" height="100px" style={{ display: this.state.editingTicket ? '' : 'none' }} alt="" />
                                        <Dropzone
                                            className="dropzone dz-clickable"
                                            accept="image/*"
                                            onDrop={(accepted, rejected) => { this.onDrop(accepted, rejected, 'emailImage') }}
                                            multiple={false}
                                            maxSize={2200000}>
                                            <div className="dz-message needsclick">
                                                <span className="fa fa-cloud-upload text-muted" style={{ display: emailImagePreview.preview ? 'none' : '' }} />
                                                <h3 style={{ display: emailImagePreview.preview ? 'none' : '' }}>Drop files here or click to upload.</h3>
                                                <img
                                                    style={{ display: emailImagePreview.preview ? '' : 'none' }}
                                                    src={emailImagePreview.preview}
                                                    width="100px"
                                                    height="auto"
                                                    alt="Drop your image here"
                                                />
                                            </div>
                                        </Dropzone>
                                    </div>
                                    <div className="form-group text-center">
                                        <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                    <PhotoshopPicker onChangeComplete={this.handleChangeComplete} color={this.state.colorToShow} onAccept={() => this.acceptPicker()} onCancel={() => this.dismissPicker()} />
                </Modal>
            </div>
        );
    }
}

export default SetUpTickets;
