import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import {appDatabasePrimaryFunctions} from '../../base';
import '../../styles/css/AdminMain.css';
import RichTextEditor from 'html-verify-react-rte';
import {isEmptyHtmlEntity, isObjectEmpty} from '../utils/HelpfulFunction';
import {onValue, ref, set} from "firebase/database";
import {showErrorPopup, showSuccessPopup} from "../utils/SwalConfig";

class SetUpRulesAndRegs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesAndRegsText: RichTextEditor.createEmptyValue(),
            howToPlayText: RichTextEditor.createEmptyValue(),
            rulesPopUpText: RichTextEditor.createEmptyValue(),
            rulesShowInAppPopUpText: RichTextEditor.createEmptyValue(),
            supportText: RichTextEditor.createEmptyValue(),
            rulesPopUpHeader: "",
            supportHeader: "",
            rulesShowInAppPopUpHeader: "",
            rulesInAppButtonText: "",
            howToPlayLink: "",
            tenantRules: null,
            loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const tenantRulesRef = ref(appDatabasePrimaryFunctions, 'tenantRules');
        onValue(tenantRulesRef, (snapshot) => {
            const data = snapshot.val() || {};

            this.setState({
                rulesAndRegsText: data.rulesAndRegsText ? RichTextEditor.createValueFromString(data.rulesAndRegsText, 'html') : RichTextEditor.createEmptyValue(),
                howToPlayText: data.howToPlayText ? RichTextEditor.createValueFromString(data.howToPlayText, 'html') : RichTextEditor.createEmptyValue(),
                rulesPopUpText: data.rulesPopUpText ? RichTextEditor.createValueFromString(data.rulesPopUpText, 'html') : RichTextEditor.createEmptyValue(),
                rulesShowInAppPopUpText: data.rulesShowInAppPopUpText ? RichTextEditor.createValueFromString(data.rulesShowInAppPopUpText, 'html') : RichTextEditor.createEmptyValue(),
                supportText: data.supportText ? RichTextEditor.createValueFromString(data.supportText, 'html') : RichTextEditor.createEmptyValue(),
                rulesPopUpHeader: data.rulesPopUpHeader || "",
                supportHeader: data.supportHeader || "",
                rulesShowInAppPopUpHeader: data.rulesShowInAppPopUpHeader || "",
                rulesInAppButtonText: data.rulesInAppButtonText || "",
                howToPlayLink: data.howToPlayLink || "",
                rulesShowInApp: data.rulesShowInApp || false,
                advanced: !!data.howToPlayText || !!data.rulesPopUpText || !!data.howToPlayLink || !!data.rulesShowInApp,
                loading: false,
            });
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        let rulesAndRegsText = this.state.rulesAndRegsText.toString('html');
        let howToPlayText = this.state.howToPlayText.toString('html');
        let rulesPopUpText = this.state.rulesPopUpText.toString('html');
        let rulesShowInAppPopUpText = this.state.rulesShowInAppPopUpText.toString('html');
        let supportText = this.state.supportText.toString('html');

        // Handle empty rich text fields
        rulesAndRegsText = isEmptyHtmlEntity(rulesAndRegsText) ? "" : rulesAndRegsText;
        howToPlayText = isEmptyHtmlEntity(howToPlayText) ? "" : howToPlayText;
        rulesPopUpText = isEmptyHtmlEntity(rulesPopUpText) ? "" : rulesPopUpText;
        rulesShowInAppPopUpText = isEmptyHtmlEntity(rulesShowInAppPopUpText) ? "" : rulesShowInAppPopUpText;
        supportText = isEmptyHtmlEntity(supportText) ? "" : supportText;

        const updateRulesObject = {
            rulesAndRegsText,
            howToPlayLink: this.state.howToPlayLink,
            howToPlayText,
            rulesPopUpText,
            rulesPopUpHeader: this.state.rulesPopUpHeader,
            rulesShowInAppPopUpText,
            rulesShowInAppPopUpHeader: this.state.rulesShowInAppPopUpHeader,
            rulesInAppButtonText: this.state.rulesInAppButtonText,
            rulesShowInApp: this.state.rulesShowInApp || false,
            supportText,
            supportHeader: this.state.supportHeader,
        };

        this.setState({ loading: true });
        set(ref(appDatabasePrimaryFunctions, 'tenantRules'), updateRulesObject)
            .then(() => {
                this.setState({ loading: false });
                showSuccessPopup('Rules and Regs Updated!', '', '');
            })
            .catch((err) => {
                this.setState({ loading: false });
                showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in');
                console.error(err);
            });
    }


    handleRichTextChange = (name, text) => {
        this.setState({ [name]:  text});
    }

    handleChange (evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'IMAGE_BUTTON'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        let rulesAndRegsText = this.state.rulesAndRegsText;
        let rulesPopUpHeader = this.state.rulesPopUpHeader;
        let rulesPopUpText = this.state.rulesPopUpText;
        let rulesInAppButtonText = this.state.rulesInAppButtonText;
        let rulesShowInAppPopUpHeader = this.state.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = this.state.rulesShowInAppPopUpText;
        let howToPlayLink = this.state.howToPlayLink;
        let howToPlayText = this.state.howToPlayText;
        let supportHeader = this.state.supportHeader;
        let supportText = this.state.supportText;
        if(isObjectEmpty(rulesPopUpText)){
            rulesPopUpText = ""
        }
        if(isObjectEmpty(rulesAndRegsText)){
            rulesAndRegsText = ""
        }
        if(isObjectEmpty(howToPlayLink)){
            howToPlayLink = ""
        }
        if(isObjectEmpty(rulesPopUpHeader)){
            rulesPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpHeader)){
            rulesShowInAppPopUpHeader = ""
        }
        if(isObjectEmpty(rulesShowInAppPopUpText)){
            rulesShowInAppPopUpText = ""
        }
        if(isObjectEmpty(rulesInAppButtonText)){
            rulesInAppButtonText = ""
        }
        if(isObjectEmpty(supportHeader)){
            supportHeader = ""
        }
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        if(!isMlbApp){
            toolbarConfig.display.push('LINK_BUTTONS')
        }
        const advanced = this.state.advanced || isMlbApp || false;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="container-out" style={{width:'45%', float:'left'}}>
                <div className="admin-form-box">
                    <form onSubmit={this.handleSubmit} id="create-game-form">
                        <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                        <div className="form-group">
                            <label htmlFor="rulesAndRegsText">Rules And Regs Text</label>
                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text will appear AFTER the mandatory confirm rules and regs button on the login form<br/><strong>Example:</strong> Welcome! To play, please confirm that you agree with the rules and regs</p>
                            <RichTextEditor id="rulesAndRegsText" name="rulesAndRegsText" toolbarConfig={toolbarConfig} value={rulesAndRegsText} onChange={(text) => this.handleRichTextChange("rulesAndRegsText", text)}/>
                        </div>
                        {!isMlbApp &&
                            <div className="form-group">
                                <label htmlFor="showAdvancedSettings">Advanced</label>
                                <br/>
                                <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                            </div>
                        }
                        {advanced &&
                        <>
                            {!isMlbApp &&
                            <>
                                <div className="form-group">
                                    <label htmlFor="howToPlayLink">How To Play Link (Optional)</label>
                                    <p className="text-muted2" style={{fontSize:'10px'}}>This is where you can teach fans how to play</p>
                                    <input id="howToPlayLink" name="howToPlayLink" type="url" className="form-control" value={howToPlayLink} onChange={this.handleChange} placeholder="https://ourgameplay.com"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="howToPlayText">How To Play Text (replaces link if filled)</label>
                                    <p className="text-muted2" style={{fontSize:'10px'}}>This is where you can teach fans how to play</p>
                                    <RichTextEditor id="howToPlayText" name="howToPlayText" toolbarConfig={toolbarConfig} value={howToPlayText} onChange={(text) => this.handleRichTextChange("howToPlayText", text)}/>
                                </div>
                            </>
                            }
                            <div className="form-group">
                                <label htmlFor="rulesPopUpHeader">Rules & Regs Pop Up Header (Optional)</label>
                                <p className="text-muted2" style={{fontSize:'10px'}}>The header of the in-app pop up containing rules</p>
                                <input id="rulesPopUpHeader" name="rulesPopUpHeader" type="text" className="form-control" value={rulesPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="rulesPopUpText">Rules & Regs Pop Up Text</label>
                                <p className="text-muted2" style={{fontSize:'10px'}}>The text of the in-app pop up. When this is filled in it will replace the rules and regs link and the text here will show up in a pop up.</p>
                                <RichTextEditor className="max-height-400" id="rulesPopUpText" name="rulesPopUpText" toolbarConfig={toolbarConfig} value={rulesPopUpText} onChange={(text) => this.handleRichTextChange("rulesPopUpText", text)}/>
                            </div>
                            {isMlbApp &&
                                <>
                                    <div className="form-group">
                                        <label htmlFor="supportHeader">Support Header</label>
                                        <p className="text-muted2" style={{fontSize: '10px'}}>The header of the in-app pop
                                            up containing support information</p>
                                        <input id="supportHeader" name="supportHeader" type="text" className="form-control"
                                               value={supportHeader} onChange={this.handleChange}
                                               placeholder="NEED SUPPORT?"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="supportText">Support Body</label>
                                        <p className="text-muted2" style={{fontSize: '10px'}}>The text of the support in-app
                                            pop up.</p>
                                        <RichTextEditor id="supportText" name="supportText" toolbarConfig={toolbarConfig} placeholder="Email customerservice@website.mlb.com with your issue and we'll be in touch!" value={supportText} onChange={(text) => this.handleRichTextChange("supportText", text)}/>
                                    </div>
                                </>
                            }

                            {!isMlbApp &&
                            <div className="form-group">
                                <label htmlFor="rulesShowInApp">Show Rules And Regs After Sign Up Screen</label>
                                <br/>
                                <input type="checkbox" checked={this.state.rulesShowInApp} id="rulesShowInApp" name="rulesShowInApp" onChange={this.handleChange}/>
                            </div>
                            }
                            {this.state.rulesShowInApp &&
                            <>
                                <div className="form-group">
                                    <label htmlFor="rulesInAppButtonText">Button Text</label>
                                    <p className="text-muted2" style={{fontSize:'10px'}}>The text of the button that links to Rules & Regs</p>
                                    <input id="rulesInAppButtonText" name="rulesInAppButtonText" type="text" className="form-control" value={rulesInAppButtonText} onChange={this.handleChange} placeholder="Rules & Regs"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="rulesShowInAppPopUpHeader">Pop Up After Sign Up (Optional)</label>
                                    <p className="text-muted2" style={{fontSize:'10px'}}>The header of the in-app pop up</p>
                                    <input id="rulesShowInAppPopUpHeader" name="rulesShowInAppPopUpHeader" type="text" className="form-control" value={rulesShowInAppPopUpHeader} onChange={this.handleChange} placeholder="Rules & Regs"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="rulesShowInAppPopUpText">Rules & Regs Pop Up Text</label>
                                    <p className="text-muted2" style={{fontSize:'10px'}}>The body text of the pop up</p>
                                    <RichTextEditor id="rulesShowInAppPopUpText" name="rulesShowInAppPopUpText" toolbarConfig={toolbarConfig} value={rulesShowInAppPopUpText} onChange={(text) => this.handleRichTextChange("rulesShowInAppPopUpText", text)}/>
                                </div>
                            </>
                            }
                        </>
                        }
                    </form>
                </div>
              </div>
              <div className="container-out" style={{width:'45%', float:'right', marginRight:'20px'}}>
                <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                  <p style={{fontSize:'20px', fontWeight:'bold'}}>Why do I need rules & regulations?</p>
                  <p className="text-muted2">While each state, country, and province is different, you will want to make sure you are compliant with all contest rules and laws.  It is your responsibility to update, maintain, & host your rules and regulations tab.</p>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpRulesAndRegs;
