import SideMenu from "./SideMenu";
import TopMenu from "./TopBar";
import React from "react";
import '../../styles/css/AdminMain.css';

const FAQPage = () => {
    const docUrl = `https://docs.google.com/document/d/1Z7Zg_H2y_wpU48jrVQw7xVStlxqTsLeF-IXvINUONms/preview`; // Replace with your Google Doc's published URL

    return (
        <div className="admin-wrapper">
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <div className="card">
                    <div className="card-body">
                        <iframe
                            src={docUrl}
                            style={{ width: '100%', height: '800px', border: 'none' }}
                            title="FAQ Document"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQPage;
