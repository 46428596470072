import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { appDatabasePrimaryFunctions } from '../../base';
import UploadImage from '../utils/UploadImage';
import { ref, onValue, set, remove, push } from"firebase/database";
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone';
import ReactPlayer from 'react-player';
import RichTextEditor from "html-verify-react-rte";
import {isEmptyHtmlEntity, validUrl} from '../utils/HelpfulFunction';
import {showConfirmPopup, showErrorPopup, showInfoPopup, showToast} from "../utils/SwalConfig";

class SetUpRacer extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        let racerList = [
            {racerName: "Biggie Bagel"},
            {racerName: "Dashing Donut"},
            {racerName: "Hot Coffee"},
            {racerName: "Iced Coffee"}
        ]
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        if(isMlbApp){
            racerList = [
                {racerName: "4th Inning Score"},
                {racerName: "5th Inning Score"},
                {racerName: "6th Inning Score"}
            ]
        }

        this.state = {
            racerList: [],
            racerName: "",
            editImage: '',
            racerWinningVideoLink: "",
            selectedRacer: {},
            modal: false,
            editing: false,
            loading:true,
            gifSelectionImage: null,
            gifSelectionImagePreview: {},
            add_video: false,
            videoLength: null,
            dunkin_donut_racers: racerList
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleVideoChange = this.handleVideoChange.bind(this);
    }

    componentDidMount() {
        const racerListRef = ref(appDatabasePrimaryFunctions, 'racerList');
        onValue(racerListRef, (snapshot) => {
            const data = snapshot.val() || {};
            const racerList = Object.keys(data).map((key) => {
                const racer = data[key];
                // Check if the racer already has an 'id' property
                if (!racer.id) {
                    racer.id = key;
                }
                return racer;
            });
            this.setState({
                racerList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
      URL.revokeObjectURL(this.state.gifSelectionImagePreview.preview);
    }

    handleChange(evt){
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    onChangeDunkinEmailDescription = (dunkinEmailDescription) => {
        this.setState({ dunkinEmailDescription});
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
          showErrorPopup('Cannot be uploaded', 'Make sure images are less than 2mbs and videos are less than 20mb and are an acceptable file type');
          return;
      }
      this.setState({loading:true})

      this.UploadImage.upload_file(files[0]).then(res => {
        this.setState({loading:false})
            if(res.error){
                showErrorPopup('Image cannot be uploaded', res.error);
            } else if(res.imageUrl) {
                const nameToUpdate = myArgument + "Preview"
                const fileToUpdate = files[0]
                this.setState({
                    [myArgument]: res.imageUrl,
                    [nameToUpdate]: ({
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    })
                });
            } else {
                showErrorPopup('Image cannot be uploaded', 'There was some issue with the image upload, please re-upload and try again');
            }
        })
    }

    handleVideoChange(evt){
        const name_of_file = evt.target.name;
        const target = this[name_of_file];
        const file_to_update =target.files[0];
        this.setState({loading:true})
        const vm = this;
        this.UploadImage.upload_file(file_to_update).then(res => {
            vm.setState({loading:false})
            if(res && res.error){
                swal({
                    title: 'Video cannot be uploaded',
                    text: res.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            } else if(res && res.imageUrl) {
                vm.setState({
                    fileUploaded: true,
                    racerWinningVideoLink: res.imageUrl
                });
            } else {
                swal({
                    title: 'Video cannot be uploaded',
                    text: "Something went wrong, please re-upload your video and try again!",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    createRacer() {
        let { racerName, racerList, racerWinningVideoLink, add_video, videoLength, gifSelectionImage, dunkinEmailDescription, editImage } = this.state;
        const racerId = this.state.editing ? this.state.selectedRacer.id : push(ref(appDatabasePrimaryFunctions, 'racerList')).key;
        const vm = this;
        const gifSelectionImageFinal = gifSelectionImage || editImage;

        if (!racerName || !gifSelectionImageFinal) {
            showErrorPopup('Oh uh!', 'Make sure the name and selection image are added!');
            return;
        }
        if (racerName.length > 50) {
            showErrorPopup('Oh uh!', 'Name cannot be longer than 50 characters!');
            return;
        }
        if (add_video && racerWinningVideoLink && !validUrl(racerWinningVideoLink)) {
            showErrorPopup('Oh uh!', 'Link is not a valid URL');
            return;
        } else if (add_video && !racerWinningVideoLink) {
            showErrorPopup('Oh uh!', "You have indicated you want to add a video but haven't added a video link nor a video file");
            return;
        }

        for (const racer of racerList) {
            if (racer.racerName === racerName && !this.state.selectedRacer) {
                showErrorPopup('Oh uh!', "This name already exists! Pick another name");
                return;
            }
        }

        this.setState({ loading: true });

        const racer = {
            racerName,
            gifSelectionImage: gifSelectionImageFinal,
            racerWinningVideoLink: add_video ? racerWinningVideoLink : "",
            dunkinEmailDescription: isEmptyHtmlEntity(dunkinEmailDescription) ? "" : dunkinEmailDescription.toString('html'),
            videoLength: add_video ? videoLength : false,
            id: racerId,
        };

        set(ref(appDatabasePrimaryFunctions, `racerList/${racerId}`), racer)
            .then(() => {
                vm.setState({
                    loading: false,
                    racerName: "",
                    racerWinningVideoLink: "",
                    gifSelectionImage: null,
                    videoLength: null,
                    dunkinEmailDescription: RichTextEditor.createEmptyValue(),
                    fileUploaded: false,
                    gifSelectionImagePreview: {},
                    editImage: "",
                    add_video: false,
                    modal: false,
                });
                showToast(this.state.editing ? 'Edited Successfully!' : 'Created Successfully!');
            })
            .catch((err) => {
                vm.setState({ loading: false });
                showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in');
                console.error(err);
            });
    }

    editRacer(event){
        event.preventDefault();
        const array = this.state.racerList;
        const racer = array[event.target.value];
        let addVideo = false;
        let fileUploaded = false;
        if(racer && racer.racerWinningVideoLink){
            addVideo = true
            fileUploaded = true
        } else if(!racer){
            showInfoPopup('Oh uh!', 'Something went wrong, refresh the page and try again!');
            return;
        }
        this.setState({
            modal: !this.state.modal,
            selectedRacer: racer,
            racerName: racer.racerName,
            racerWinningVideoLink: racer.racerWinningVideoLink,
            add_video: addVideo,
            dunkinEmailDescription: racer.dunkinEmailDescription,
            videoLength: racer.videoLength,
            editingImagePreview: racer.gifSelectionImage,
            editImage: racer.gifSelectionImage,
            fileUploaded: fileUploaded,
            editing: true
        });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        selectedRacer: {},
        racerName: "",
        racerWinningVideoLink: "",
        editingImagePreview: null,
        dunkinEmailDescription: RichTextEditor.createEmptyValue(),
        editImage: '',
        videoLength: null,
        editing: false,
        add_video: false
      });
    }

    async deleteRacer(e) {
        e.preventDefault();
        const index = this.state.racerList[e.target.value];

        const result = await showConfirmPopup('Delete?', 'Are you sure you want to do this? You will no longer be able to use it in any new games')
        if (!result) return;
        remove(ref(appDatabasePrimaryFunctions, `racerList/${index.id}`))
        .then(() => {
            showToast('Deleted Successfully!');
        })
        .catch((err) => {
            showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in');
            console.error(err);
        })
    }

    render() {
        const racerList = this.state.racerList;
        const isPrediction = process.env.REACT_APP_RACE_TYPE === "prediction";
        let selectedRacer = this.state.selectedRacer;
        const gifSelectionImagePreview = this.state.gifSelectionImagePreview;
        if(Object.keys(selectedRacer).length === 0){
            selectedRacer = null
        }
        const isDunkin = process.env.REACT_APP_VERSION === "dunkin";
        let labelText = "Racers";
        if(isPrediction){
            labelText = "Predictions";
        } else if(process.env.REACT_APP_FIREBASE_PROJECT_ID.indexOf("4th5th6th") !== -1) {
            labelText = "Innings";
        }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>{labelText}</p>
                    <p className="admin-subheader-text">These are what picks will appear during your contest!</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                      {
                        racerList.map(function(item,i){
                          return <div key={i} className="card">
                              <div className="card-body" align="center">
                                <p style={{marginTop:5}}>{item.racerName}</p>
                                <p>
                                  <img width="80%" height="auto" src={item.gifSelectionImage} alt=""/>
                                </p>
                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editRacer.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteRacer.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                              </div>
                            </div>
                        }, this)
                      }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <ModalHeader toggle={this.toggle}>Add/Edit</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <div className="form-group" >
                        <label htmlFor="racerName">Name</label>
                          { isDunkin ?
                              <select className="form-control" name="racerName" id="racerName" value={this.state.racerName} onChange={this.handleChange}>
                                    <option/>
                                    {
                                      this.state.dunkin_donut_racers.map(function (item, index) {
                                          return (
                                              <option value={item.racerName} key={index}>{item.racerName}</option>
                                          )
                                      })
                                    }
                              </select>
                             :
                              <input type="text" id="racerName" name="racerName" className="form-control" value={this.state.racerName} onChange={this.handleChange} placeholder="A unique name to help with identification"/>
                          }
                      </div>
                        {/*{ isDunkin && !isMlbApp &&*/}
                        {/*    <div className="form-group">*/}
                        {/*        <label htmlFor="dunkinEmailDescription">Winner Email Description Text</label>*/}
                        {/*        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text will appear in the winning email when this racer wins</p>*/}
                        {/*        <RichTextEditor toolbarConfig={toolbarConfig} style={{minHeight:'100px'}} id="dunkinEmailDescription" name="dunkinEmailDescription" value={dunkinEmailDescription} onChange={this.onChangeDunkinEmailDescription} />*/}
                        {/*    </div>*/}
                        {/*}*/}

                        <div className="form-group row mb-3">
                            <span className="col-md-1" style={{paddingRight:0, paddingTop:2}}>
                                <input id="add_video" name="add_video" type="checkbox" checked={this.state.add_video} onChange={this.handleChange} />
                            </span>
                            <label className="col-md-10 col-form-label" htmlFor="add_video" style={{padding:0,margin:0}}>Add Video <span style={{display: this.state.add_video?"":"none"}}>-- Recommended: Dimensions = 16:9, Size = 1280x720, less then 20MB</span></label>
                        </div>
                        <div style={{display: this.state.add_video ? "" : "none"}}>
                            <div className="form-group row" style={{textAlign: "center", alignItems:"center", display: this.state.fileUploaded? 'none' : ''}}>
                                <div className="col-md-6">
                                    <label htmlFor="racerWinningVideoLink">Winning Video Link</label>
                                    <input type="url" id="racerWinningVideoLink" name="racerWinningVideoLink" className="form-control" value={this.state.racerWinningVideoLink} onChange={this.handleChange} placeholder="https://mywinningvideolink.com"/>
                                </div>
                                <div className="col-md-1">
                                    <h2><b>OR</b></h2>
                                </div>
                                <div className="col-md-2">
                                    <input style={{display:'none'}}  id="raceVideoFile" name="raceVideoFile" type="file" ref={input => {this.raceVideoFile = input; }} onChange={this.handleVideoChange}/>
                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Video (MP4)" onClick={() => document.getElementById('raceVideoFile').click()} />
                                </div>
                            </div>
                            <div style={{width: 300, margin: "auto", textAlign: "center", display: this.state.racerWinningVideoLink? '' : 'none'}}>
                                Preview <span style={{cursor:"pointer"}} onClick={()=>this.setState({racerWinningVideoLink: null, fileUploaded:false}, () => {
                                    document.getElementById('racerWinningVideoLink').value="";
                            })}> ❌</span>
                                {this.state.add_video && this.state.racerWinningVideoLink && !this.state.videoLength &&
                                    <p style={{color:"red"}}>Video error: video couldn't play or is still loading</p>
                                }
                                <ReactPlayer
                                    style={{display: this.state.racerWinningVideoLink? '' : 'none'}}
                                    url={this.state.racerWinningVideoLink}
                                    onDuration={(e)=> this.setState({videoLength: e})}
                                    onError={(e)=>this.setState({videoLength: null})}
                                    muted={true}
                                    playing={true}
                                    controls={true}
                                    preload={"auto"}
                                    width="100%"
                                    height="auto"
                                />
                            </div>
                        </div>
                      <div className="form-group" align="center">
                        <label htmlFor="gifSelectionImage" style={{width:'100%'}}>Selection Image (Max width: 500px)</label>
                        <img src={this.state.editImage} width="auto" height="100px" style={{display: this.state.editing ? '' : 'none' }} alt=""/>
                        <Dropzone
                          className="dropzone dz-clickable"
                          accept="image/*"
                          onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'gifSelectionImage')}}
                          multiple={false}
                          maxSize={2200000}>
                          <div className="dz-message needsclick">
                            <span className="fa fa-cloud-upload text-muted" style={{display: gifSelectionImagePreview.preview ? 'none' : ''}}/>
                            <h3 style={{display: gifSelectionImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                            <img
                              style={{display: gifSelectionImagePreview.preview ? '' : 'none'}}
                              src={gifSelectionImagePreview.preview}
                              width="100px"
                              height="auto"
                              alt="Drop your image here"
                            />
                          </div>
                        </Dropzone>
                      </div>
                      <div className="form-group text-center">
                        <button disabled={this.state.add_video && this.state.racerWinningVideoLink && !this.state.videoLength} className="btn btn-primary btn-lg submit-button" id="submitButton" onClick={()=>this.createRacer()}>Submit</button>
                      </div>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
         </div>
        );
    }
}

export default SetUpRacer
